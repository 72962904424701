<mat-icon class="__item-type-icon __fee-icon">call_received</mat-icon>

<span class="__item_main">
  <vshcz-price
    [priceSize]="priceSize"
    [symbolSize]="symbolSize"
    [amount]="totalDue"
    [currencyId]="currencyId"
    [currencyMap]="currencyMap">
  </vshcz-price>
</span>

<span class="__item_secondary">

  <span class="__title" [translate]="'feeTypes.' + type"></span>

  <span class="__id">
    <div>
      {{ 'feeOrder' | translate }}
    </div>
    # <strong>{{ number }}</strong>
  </span>

</span>

<vshcz-fee-type
  class="__type"
  [type]="type"
  [tooltip]="'feeStatuses.tooltips.WAITING_FOR_PAYMENT' | translate">
</vshcz-fee-type>