<div
  *ngIf="isDialog"
  class="__payment-info-table">

  <div
    *ngIf="credit > 0 || cardFee > 0"
    class="__payment-info-row"
    [ngClass]="{ '__payment-info-first-row': credit > 0 || cardFee > 0 ? true : false }">

    <div class="__payment-info-left-cell" [translate]="isFeePayment ? 'billingCard.unpaidFees' : 'billingCard.unpaidInvoices'"></div>

    <div class="__payment-info-right-cell">
      <vshcz-price
        mode="bold"
        [amount]="total"
        [currencyId]="currencyId"
        [currencyMap]="currencyMap"
        [priceSize]="20"
        [symbolSize]=13>
      </vshcz-price>
    </div>

  </div>

  <div
    *ngIf="credit > 0"
    class="__payment-info-row">

    <div class="__payment-info-left-cell" translate="billingCard.yourCredit"></div>

    <div class="__payment-info-right-cell __payment-info-credit">
      - &nbsp;
      <vshcz-price
        mode="bold"
        [amount]="credit"
        [currencyId]="currencyId"
        [currencyMap]="currencyMap"
        [priceSize]="20"
        [symbolSize]="13">
      </vshcz-price>
    </div>

  </div>

  <div
    *ngIf="cardFee > 0"
    class="__payment-info-row">

    <div class="__payment-info-left-cell">{{ "billingCard.feeCard" | translate: { feePercent: cardFeePercent.toFixed(1) } }}</div>

    <div class="__payment-info-right-cell __payment-info-fee">
      + &nbsp;
      <vshcz-price
        mode="bold"
        [amount]="cardFee"
        [currencyId]="currencyId"
        [currencyMap]="currencyMap"
        [priceSize]="20"
        [symbolSize]="13">
      </vshcz-price>
    </div>

  </div>

  <div
    class="__payment-info-row"
    [ngClass]="{
      '__payment-info-last-row': credit > 0 || cardFee > 0 ? true : false,
      '__payment-info-one-last-row': credit === 0 && cardFee === 0 ? true : false
    }">

    <div class="__payment-info-left-cell" translate="billingCard.totalToPay"></div>

    <div class="__payment-info-right-cell">
      <vshcz-price
        mode="bold"
        [amount]="notApplyCredit ? total : total - credit + cardFee"
        [currencyId]="currencyId"
        [currencyMap]="currencyMap"
        [priceSize]="20"
        [symbolSize]=13>
      </vshcz-price>
    </div>

  </div>

</div>

<div
  class="__price"
  *ngIf="!isDialog">

  <vshcz-price
    mode="bold-symbol"
    [amount]="notApplyCredit ? total : total - credit + cardFee"
    [currencyId]="currencyId"
    [currencyMap]="currencyMap"
    [priceSize]="34"
    [symbolSize]=13>
  </vshcz-price>

  <span
    *ngIf="!credit || notApplyCredit"
    class="__price_currency">
    / <span translate="bulkPayment.total"></span>
  </span>

</div>

<!-- credit -->
<div
  *ngIf="!isDialog && credit && credit > 0 && !notApplyCredit"
  class="__credit">

  <span class="__amount-sign">
      ( -
  </span>

  <vshcz-price
    mode="bold"
    class="__amount-price"
    [amount]="credit"
    [currencyId]="currencyId"
    [currencyMap]="currencyMap"
    [priceSize]="18"
    [symbolSize]="12">
  </vshcz-price>

  <span
    *ngIf="mode === 'short'"
    class="__amount-text"
    translate="bulkPayment.creditShort">
  </span>

  <span
    *ngIf="mode === 'long'"
    class="__amount-text"
    translate="bulkPayment.creditLong">
  </span>

  <span class="__amount-sign">
    )
  </span>

</div>

<!-- card fee -->
<div
  *ngIf="!isDialog && cardFee && cardFee > 0"
  class="__card-fee">

  <span class="__amount-sign">
      ( +
  </span>

  <vshcz-price
    mode="bold"
    class="__amount-price"
    [amount]="cardFee"
    [currencyId]="currencyId"
    [currencyMap]="currencyMap"
    [priceSize]="18"
    [symbolSize]="12">
  </vshcz-price>

  <span
    *ngIf="mode === 'short'"
    class="__amount-text"
    translate="bulkPayment.cardFeeShort">
  </span>

  <span
    *ngIf="mode === 'long'"
    class="__amount-text"
    translate="bulkPayment.cardFeeLong">
  </span>

  <span class="__amount-sign">
    )
  </span>

</div>