var _a, _b;
export var InvoiceStatuses;
(function (InvoiceStatuses) {
    InvoiceStatuses["PaymentOverdue"] = "PAYMENT_OVERDUE";
    InvoiceStatuses["WaitingForPayment"] = "WAITING_FOR_PAYMENT";
    InvoiceStatuses["Paid"] = "PAID";
    InvoiceStatuses["Canceled"] = "CANCELED";
})(InvoiceStatuses || (InvoiceStatuses = {}));
export var FeeTypes;
(function (FeeTypes) {
    FeeTypes["Order"] = "order";
    FeeTypes["ChangeOrder"] = "changeOrder";
})(FeeTypes || (FeeTypes = {}));
export var ModuleTokens;
(function (ModuleTokens) {
    ModuleTokens["Name"] = "invoicesBase";
})(ModuleTokens || (ModuleTokens = {}));
export var InvoiceTypes;
(function (InvoiceTypes) {
    InvoiceTypes["clientServices"] = "CLIENT_SERVICES";
    InvoiceTypes["credit"] = "CREDIT";
    InvoiceTypes["fee"] = "FEE";
})(InvoiceTypes || (InvoiceTypes = {}));
export var PaymentIntentTypes;
(function (PaymentIntentTypes) {
    PaymentIntentTypes["Invoice"] = "INVOICE";
    PaymentIntentTypes["Fee"] = "FEE";
})(PaymentIntentTypes || (PaymentIntentTypes = {}));
export var StripeDeclineCodesCustom;
(function (StripeDeclineCodesCustom) {
    StripeDeclineCodesCustom["INSUFFICIENT_FUNDS"] = "insufficient_funds";
})(StripeDeclineCodesCustom || (StripeDeclineCodesCustom = {}));
export var StripePaymentStatuses;
(function (StripePaymentStatuses) {
    StripePaymentStatuses["IntentRequestInit"] = "INTENT_REQUEST_INIT";
    StripePaymentStatuses["IntentRequestSuccess"] = "INTENT_REQUEST_SUCCESS";
    StripePaymentStatuses["IntentRequestFailed"] = "INTENT_REQUEST_FAILED";
    StripePaymentStatuses["PaymentRequestInit"] = "PAYMENT_REQUEST_INIT";
    StripePaymentStatuses["PaymentRequestSuccess"] = "PAYMENT_REQUEST_SUCCESS";
    StripePaymentStatuses["PaymentRequestFailed"] = "PAYMENT_REQUEST_FAILED";
})(StripePaymentStatuses || (StripePaymentStatuses = {}));
export var INVOICE_ORDER_PRIORITY_MAP = (_a = {},
    _a[InvoiceStatuses.PaymentOverdue] = 2,
    _a[InvoiceStatuses.WaitingForPayment] = 1,
    _a[InvoiceStatuses.Paid] = 0,
    _a[InvoiceStatuses.Canceled] = 0,
    _a);
export var FEE_ORDER_PRIORITY_MAP = (_b = {},
    _b[FeeTypes.Order] = 1,
    _b[FeeTypes.ChangeOrder] = 0,
    _b);
