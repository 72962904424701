import { createFeatureSelector, createSelector } from '@ngrx/store';
import orderBy from 'lodash-es/orderBy';
import { InvoicesBaseState, InvoiceEntity } from './invoices-base.model';
import { ModuleTokens, InvoiceStatuses, StripePaymentStatuses } from './invoices-base.constant';
import { denormalizeList } from './invoices-base.utils';
import { PaymentIntentTypes } from './invoices-base.constant';

export const slice = createFeatureSelector<InvoicesBaseState>(ModuleTokens.Name);

export const entities = createSelector(
  slice,
  (s) => s.entities
);

export const listIds = createSelector(
  slice,
  (s) => s.list
);

export const listEntities = createSelector(
  listIds,
  entities,
  (ids, ents) => {

    if (!ents) { return undefined; }

    return denormalizeList(
      ids,
      ents[ModuleTokens.Name]
    ) as InvoiceEntity[];
  }
);

export const overdueInvoices = createSelector(
  listEntities,
  (invoices) => invoices && invoices.length
    ? invoices.filter((invoice) => invoice.status === InvoiceStatuses.PaymentOverdue)
    : undefined
);

export const unpaidInvoices = createSelector(
  listEntities,
  (invoices) => invoices && invoices.length
    ? invoices.filter((invoice) => invoice.status === InvoiceStatuses.PaymentOverdue
        || invoice.status === InvoiceStatuses.WaitingForPayment
      )
    : undefined
);

export const clientInvoiceLiabilities = createSelector(
  slice,
  (s) => s.invoiceLiabilities
);

export const clientFeeLiabilities = createSelector(
  slice,
  (s) => s.feeLiabilities
);

export const getActivePayment = createSelector(
  slice,
  (s) => s.activePayment
);

export const paymentBackendProcessing = (
  instanceKind: 'dashboard' | 'menu' | 'route',
  paymentIntentType: PaymentIntentTypes
) => createSelector(
  slice,
  (s) => {
    const existUnpaidLiabilities = s.activePayment
      && s.activePayment.type === paymentIntentType
      && s.activePayment.type === PaymentIntentTypes.Invoice
        ? s.invoiceLiabilities && (
          s.invoiceLiabilities.unpaidInvoices.length > 0
          // || s.invoiceLiabilities.bankTransferSummary.credit > 0
        )
        : s.activePayment && s.activePayment.type === paymentIntentType
          ? s.feeLiabilities && (
            s.feeLiabilities.unpaidFees.length > 0
            // || s.feeLiabilities.bankTransferSummary.credit > 0
          )
          : false;

    return existUnpaidLiabilities
      && s.activePayment
      && s.activePayment.instanceKind === instanceKind
      && s.activePayment.status === StripePaymentStatuses.PaymentRequestSuccess;
  }
);

export const paidInvoices = createSelector(
  listEntities,
  (invoices) => invoices && invoices.length
    ? invoices.filter((invoice) => invoice.status !== InvoiceStatuses.PaymentOverdue
        && invoice.status !== InvoiceStatuses.WaitingForPayment
      )
    : undefined
);

export const oldestUnpaidInvoice = createSelector(
  listEntities,
  (invoices) => {
    const orderedInvs = orderBy(invoices, [ 'created' ], [ 'desc' ]);

    return orderedInvs.find((invoice) => invoice.status === InvoiceStatuses.PaymentOverdue
      || invoice.status === InvoiceStatuses.WaitingForPayment
    );
  }
);
