<ng-container *ngIf="(clientInvoiceLiabilities$ | async) as clientInvoiceLiabilities">
  <ng-container *ngIf="(clientFeeLiabilities$ | async) as clientFeeLiabilities">

  <div *ngIf="!(onFeePaymentBackendProcessing$ | async) as feePaymentBackendProcessing">
    <div
      *ngIf="clientInvoiceLiabilities.bankTransferSummary.totalDue > 0"
      class="__secondary">
      <div class="__wrap">

        <div class="__loading" *ngIf="(onInvoicePaymentBackendProcessing$ | async) as invoicePaymentBackendProcessing">
          <div class="__loading_content">
            <mat-progress-spinner
              class="__spinner"
              diameter="24"
              mode="indeterminate">
            </mat-progress-spinner>
            <p class="__spinner_label" translate="bulkPayment.infoPairingPayment">
            </p>
          </div>
        </div>
        <div class="__content">
          <vshcz-bulk-payment-overview
            [instanceKind]="instanceKind"
            [notApplyCredit]="true || clientFeeLiabilities.bankTransferSummary.totalDue > 0"
            [isFeePayment]="false"
            [total]="clientInvoiceLiabilities.bankTransferSummary.totalLiabilities"
            [credit]="clientInvoiceLiabilities.bankTransferSummary.credit"
            [unpaidCount]="clientInvoiceLiabilities.unpaidInvoices.length"
            [currencyMap]="currencyMap$ | async"
            [currencyId]="(client$ | async)?.currencyId"
            [qrData]="invoicesQrData$ | async"
            [cardPaymentEnabled]="clientInvoiceLiabilities.cardPaymentEnabled">
          </vshcz-bulk-payment-overview>
        </div>

      </div>
    </div>
  </div>

  <div *ngIf="!(onInvoicePaymentBackendProcessing$ | async) as invoicePaymentBackendProcessing">
    <div
      *ngIf="clientFeeLiabilities.bankTransferSummary.totalDue > 0"
      class="__secondary">
      <div class="__wrap">

        <div class="__loading" *ngIf="(onFeePaymentBackendProcessing$ | async) as feePaymentBackendProcessing">
          <div class="__loading_content">
            <mat-progress-spinner
              class="__spinner"
              diameter="24"
              mode="indeterminate">
            </mat-progress-spinner>
            <p class="__spinner_label" translate="bulkPayment.infoPairingPayment">
            </p>
          </div>
        </div>

        <div class="__content">
          <vshcz-bulk-payment-overview
            [instanceKind]="instanceKind"
            [notApplyCredit]="true || clientInvoiceLiabilities.bankTransferSummary.totalDue > 0"
            [isFeePayment]="true"
            [total]="clientFeeLiabilities.bankTransferSummary.totalLiabilities"
            [credit]="clientFeeLiabilities.bankTransferSummary.credit"
            [unpaidCount]="clientFeeLiabilities.unpaidFees.length"
            [currencyMap]="currencyMap$ | async"
            [currencyId]="(client$ | async)?.currencyId"
            [qrData]="feesQrData$ | async"
            [cardPaymentEnabled]="clientFeeLiabilities.cardPaymentEnabled">
          </vshcz-bulk-payment-overview>
        </div>

      </div>
    </div>
  </div>

  <mat-divider *ngIf="clientInvoiceLiabilities.bankTransferSummary.totalDue > 0 || clientFeeLiabilities.bankTransferSummary.totalDue > 0"></mat-divider>

  </ng-container>
</ng-container>

<div class="__content __content--main">
  <div
    fxLayout="row wrap"
    fxLayoutAlign="start start"
    fxLayoutGap="24px grid">

    <div [fxFlex]="leftLayout">

      <!-- invoices -->
      <h5 class="__title  c-button-title">
        <span class="c-button-title__text"
          [innerHTML]="'billingCard.latestInvoices' | translate">
        </span>

        <a
          [routerLink]="[ '/', 'invoices', 'list' ]"
          mat-stroked-button
          color="accent"
          class="__link  c-button-title__link"
          [innerHTML]="'billingCard.seeAll' | translate"
          (click)="contentClicked.emit()">
        </a>
      </h5>

      <div *ngIf="(latestInvoices$ | async) as latestInvoices">

        <ng-container *ngIf="latestInvoices?.length === 0">
          <span
            class="__item-component __no-items"
            translate="billingCard.noRecords">
          </span>
        </ng-container>

        <ng-container *ngIf="latestInvoices?.length > 0">
          <ng-container *ngFor="let invoice of latestInvoices; let last = last; trackBy: _trackBy;">
            <a
              [routerLink]="[ '/', 'invoices', 'detail', invoice.id]"
              [class.is-last]="last"
              class="__item"
              (click)="contentClicked.emit()">
              <vshcz-invoice-item
                class="__item-component"
                [id]="invoice.id"
                [number]="invoice.number"
                [totalDue]="invoice.totalDue"
                [currencyId]="invoice.currencyId"
                [created]="invoice.created"
                [status]="invoice.status"
                [daysOverdue]="invoice.daysOverdue"
                [dueDate]="invoice.dueDate"
                [paidDate]="invoice.paidDate"
                [currencyMap]="currencyMap$ | async">
              </vshcz-invoice-item>
            </a>
          </ng-container>
        </ng-container>

      </div>

      <br />

      <!-- fees -->
      <div *ngIf="(unpaidFees$ | async) as fees">

        <h5
          *ngIf="fees?.length > 0"
          class="__title c-button-title">
          <span class="c-button-title__text"
            [innerHTML]="'billingCard.unpaidFees' | translate">
          </span>
        </h5>

        <ng-container *ngIf="fees?.length > 0">
          <ng-container *ngFor="let fee of fees; let last = last; trackBy: _trackBy;">
            <span
              [class.is-last]="last"
              class="__item">
              <vshcz-fee-item
                class="__item-component"
                [id]="fee.id"
                [number]="fee.number"
                [totalDue]="fee.totalDue"
                [currencyId]="(client$ | async)?.currencyId"
                [type]="fee.type"
                [currencyMap]="currencyMap$ | async">
              </vshcz-fee-item>
            </span>
          </ng-container>
        </ng-container>

      </div>

    </div>

    <!-- payments -->
    <div [fxFlex]="rightLayout">
      <h5 class="__title c-button-title">
        <span
          class="c-button-title__text"
          [innerHTML]="'billingCard.lastPayments' | translate">
        </span>
        <a
          [routerLink]="[ '/', 'payments', 'list' ]"
          mat-stroked-button
          (click)="contentClicked.emit()"
          color="accent"
          class="__link  c-button-title__link"
          [innerHTML]="'billingCard.seeAll' | translate">
        </a>
      </h5>

      <div *ngIf="(latestPayments$ | async) as latestPayments">

        <ng-container *ngIf="latestPayments?.length === 0">
          <span
            class="__item-component __no-items"
            translate="billingCard.noRecords">
          </span>
        </ng-container>

        <ng-container *ngIf="latestPayments?.length > 0">
          <ng-container *ngFor="let payment of latestPayments; let last = last; trackBy: _trackBy;">
            <a
              [routerLink]="[ '/', 'payments', 'detail', payment.id]"
              [class.is-last]="last"
              class="__item"
              (click)="contentClicked.emit()">
              <vshcz-payment-item
                class="__item-component"
                [id]="payment.id"
                [amount]="payment.amount"
                [currencyId]="payment.currencyId"
                [created]="payment.created"
                [status]="payment.status"
                [currencyMap]="currencyMap$ | async">
              </vshcz-payment-item>
            </a>
          </ng-container>
        </ng-container>

      </div>

    </div>

  </div>
</div>
