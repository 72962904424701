<zps-dialog
  class="__dialog"
  *ngIf="(paymentKind$ | async) as paymentKind"
  [autoFocus]="false"
  [open]="open$ | async">
  <div
    *ngIf="(clientInvoiceLiabilities$ | async) as clientInvoiceLiabilities"
    class="__content">

    <vshcz-content-title
      size="big"
      position="center"
      class="__title">
      <span
        [innerHtml]="'bulkPayment.dialogTitle' | translate: { count: clientInvoiceLiabilities.unpaidInvoices.length }">
      </span>
    </vshcz-content-title>

    <span
      class="__info"
      [translate]="paymentKind === paymentKinds.Online ? 'bulkPayment.dialogPaymentOnlineText' : 'bulkPayment.dialogText'">
    </span>

    <ngx-qrcode
      *ngIf="paymentKind === paymentKinds.Transfer"
      class="__qr"
      [qrc-value]="(qrData$ | async)">
    </ngx-qrcode>

    <div *ngIf="paymentKind === paymentKinds.Online" class="__stripe-card-wrap">
      <div class="__stripe-card">

        <span class="__badge">
          <span class="__badge-content">

            <span
              translate="paymentByStripe.label">
            </span>

            <mat-icon
              class="__stripe-icon"
              svgIcon="stripe">
            </mat-icon>

          </span>
        </span>

        <ngx-stripe-card
          [options]="cardOptions"
          [elementsOptions]="elementsOptions">
        </ngx-stripe-card>

      </div>
    </div>

    <div
      fxLayout="row wrap"
      fxLayoutAlign="center start"
      fxLayoutGap="24px grid">

      <div
        fxFlex="50"
        fxFlex.xs="80"
        class="__payment-info">

        <span
          class="__sub-title"
          translate="bulkPayment.info">
        </span>

        <span class="__price">

          <vshcz-bulk-payment-price
            mode="short"
            [isDialog]="true"
            [isFeePayment]="false"
            [currencyId]="(client$ | async)?.currencyId"
            [currencyMap]="currencyMap$ | async"
            [credit]="clientInvoiceLiabilities.bankTransferSummary.credit"
            [total]="clientInvoiceLiabilities.bankTransferSummary.totalLiabilities"
            [cardFee]="paymentKind === paymentKinds.Online ? clientInvoiceLiabilities.cardPaymentSummary?.cardPaymentFeeAmount : 0"
            [cardFeePercent]="paymentKind === paymentKinds.Online ? clientInvoiceLiabilities.cardPaymentSummary?.cardPaymentFeePercent : 0">
          </vshcz-bulk-payment-price>

        </span>

        <span *ngIf="paymentKind === paymentKinds.Transfer">

          <span class="__info_item" *ngIf="clientInvoiceLiabilities.bankAccount.localPayment">
            <span
              class="__info_item_title"
              [class.is-hu]="(currentLang$ | async) === 'hu'"
              translate="bulkPayment.id">
            </span>
            <span class="__info_item_text">{{ clientInvoiceLiabilities.bankAccount.variableSymbol }}</span>
          </span>

          <ng-container *ngIf="!clientInvoiceLiabilities.bankAccount.localPayment; else accountRef">

            <span class="__info_item">
              <span
              class="__info_item_title"
              [class.is-hu]="(currentLang$ | async) === 'hu'">
              IBAN
              </span>
              <span class="__info_item_text">{{ clientInvoiceLiabilities.bankAccount.iban }}</span>
            </span>

            <span class="__info_item">
              <span
                class="__info_item_title"
                [class.is-hu]="(currentLang$ | async) === 'hu'">
                SWIFT
              </span>
              <span class="__info_item_text">{{ clientInvoiceLiabilities.bankAccount.swift }}</span>
            </span>

          </ng-container>

          <ng-template #accountRef>
            <span class="__info_item">
              <span
                class="__info_item_title"
                translate="bulkPayment.bankAccount"
                [class.is-hu]="(currentLang$ | async) === 'hu'">
              </span>
              <span class="__info_item_text">{{ clientInvoiceLiabilities.bankAccount.localAccountNumber }}</span>
            </span>
          </ng-template>

          <span class="__info_item">
            <span
              class="__info_item_title"
              translate="bulkPayment.bankName"
              [class.is-hu]="(currentLang$ | async) === 'hu'">
            </span>
            <span class="__info_item_text">{{ clientInvoiceLiabilities.bankAccount.bankName }}</span>
          </span>

          <span class="__info_item" *ngIf="!clientInvoiceLiabilities.bankAccount.localPayment">
            <span
              class="__info_item_title"
              translate="bulkPayment.desc"
              [class.is-hu]="(currentLang$ | async) === 'hu'">
            </span>
            <span class="__info_item_text">{{ clientInvoiceLiabilities.bankAccount.paymentNote }}</span>
          </span>

        </span>

      </div>

      <div
        fxFlex="40"
        fxFlex.xs="80"
        class="__invoices"
        [ngClass.xs]="'is-wrapped'"
        *ngIf="(unpaidInvoices$ | async) as unpaidInvoices">

        <span
          class="__sub-title  __sub-title--margin-left"
          translate="bulkPayment.toBePaid">
        </span>

        <vshcz-scrollable [maxHeight]="81 * 3">

          <a
            target="_blank"
            class="__item"
            *ngFor="let invoice of unpaidInvoices; let last = last"
            [routerLink]="[ '/', 'invoices', 'detail', invoice.id]">
            <vshcz-invoice-item
              [created]="invoice.created"
              [currencyId]="invoice.currencyId"
              [currencyMap]="currencyMap$ | async"
              [daysOverdue]="invoice.daysOverdue"
              [dueDate]="invoice.dueDate"
              [id]="invoice.id"
              [number]="invoice.number"
              [paidDate]="invoice.paidDate"
              [totalDue]="invoice.totalDue"
              [status]="invoice.status"
              [symbolSize]="13"
              [class.is-last]="last">
            </vshcz-invoice-item>
          </a>

        </vshcz-scrollable>

      </div>

    </div>

    <button
      class="__close"
      mat-icon-button
      *ngIf="!(onActivePayment$ | async)"
      [zpsNgrxDialogClose]="dialogKey"
      (click)="onClose$.next()">
      <mat-icon>close</mat-icon>
    </button>

    <div *ngIf="paymentKind === paymentKinds.Online" class="__buttons">
      <div>
        <vshcz-progress-error
          [errorCloseLabel]="'common.close' | translate"
          [errorRetryLabel]="'common.retry' | translate"
          [errorShowDetailsLabel]="'common.showMore' | translate"
          [errorHideDetailsLabel]="'common.hideMore' | translate"
          [errorKey]="[paymentIntentFailKey, confirmPaymentFailKey]"
          (retry)="onTopUp$.next()"
          [progressKey]="[paymentIntentRequestKey, confirmPaymentRequestKey]">
          <button
            mat-raised-button
            color="accent"
            class="__top-up-button"
            [class.is-disabled]="!isCardEntered"
            (click)="onTopUp$.next()"
            type="button">
            <span translate="bulkPayment.paymentOnlineButton"></span>
          </button>
        </vshcz-progress-error>
      </div>
    </div>

  </div>
</zps-dialog>
