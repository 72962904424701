import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { BaseClass } from '@zerops/fe/core';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, filter, map, share, shareReplay, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { combineLatest, interval, merge, of } from 'rxjs';
import orderBy from 'lodash-es/orderBy';
import { paymentsListEntities } from '@app/base/payments-base';
import { PaymentIntentTypes, invoicesListEntities } from '@app/base/invoices-base';
import { currencyMap } from '@app/common/settings';
import { getPaymentQR } from '@app/base/payments-base/payments-base.utils';
import { activeUserClient } from '@app/base/auth-base/auth-base.selector';
import { clientInvoiceLiabilities, clientFeeLiabilities, paymentBackendProcessing, getActivePayment } from '@app/base/invoices-base/invoices-base.selector';
import { LiabilityFeeRequest, LiabilityInvoiceRequest, ListRequest as InvoiceListRequest } from '@app/base/invoices-base/invoices-base.action';
import { ListRequest as PaymentListRequest } from '@app/base/payments-base/payments-base.action';
import { SnackService } from '../snack';
import { TranslateService } from '@ngx-translate/core';
import { StripePaymentStatuses } from '@app/base/invoices-base/invoices-base.constant';
var BillingCardContainer = /** @class */ (function (_super) {
    tslib_1.__extends(BillingCardContainer, _super);
    function BillingCardContainer(_store, _snack, _translate) {
        var _this = _super.call(this) || this;
        _this._store = _store;
        _this._snack = _snack;
        _this._translate = _translate;
        _this._repeatReloadCount = 8;
        _this._repeatTiming = 2000;
        _this._reloadListDone = false;
        _this._reloadListCount = 0;
        /**
         * A flag that identifies the appropriate instance of the component is used
         * to compare it to the state of a processed payment in the store.
         */
        _this.instanceKind = 'dashboard';
        // -- async
        _this.latestPayments$ = _this._store.pipe(select(paymentsListEntities), map(function (payments) { return orderBy(payments, ['created'], ['desc']).slice(0, 5); }));
        _this.latestInvoices$ = _this._store.pipe(select(invoicesListEntities), map(function (invoices) {
            if (invoices) {
                return invoices.slice(0, 5);
            }
            return invoices;
        }));
        _this.clientInvoiceLiabilities$ = _this._store.pipe(select(clientInvoiceLiabilities));
        _this.clientFeeLiabilities$ = _this._store.pipe(select(clientFeeLiabilities));
        _this.unpaidFees$ = _this.clientFeeLiabilities$.pipe(filter(function (d) { return !!d; }), map(function (feeLiabilities) { return feeLiabilities.unpaidFees.slice(0, 5); }));
        _this.client$ = _this._store.pipe(select(activeUserClient));
        _this.invoicesQrData$ = combineLatest(_this.client$, _this.clientInvoiceLiabilities$, _this.clientFeeLiabilities$).pipe(map(function (_a) {
            var client = _a[0], invoiceLiabilities = _a[1], feeLiabilities = _a[2];
            return !!client && !!invoiceLiabilities && !!feeLiabilities
                ? getPaymentQR(
                /**
                 * The iban account number is always defined.
                 */
                invoiceLiabilities.bankAccount.iban, 
                /**
                 * If there are both unpaid invoices and unpaid fees, do not count a credit, if it exists,
                 * and use only the total invoice amount owed. The reason is to avoid a misunderstanding
                 * when the same credit is applied in both places at the same moment.
                 * If there are only unpaid invoices then use the total invoice amount owed, less credit,
                 * if any, but only if the result is greater than 0, otherwise 0.
                 */
                +(invoiceLiabilities.bankTransferSummary.totalLiabilities).toFixed(2), 
                /*
                invoiceLiabilities.bankTransferSummary.totalDue > 0 && feeLiabilities.bankTransferSummary.totalDue > 0
                  ? +(invoiceLiabilities.bankTransferSummary.totalLiabilities).toFixed(2)
                  : invoiceLiabilities.bankTransferSummary.totalDue > 0
                    ? +(invoiceLiabilities.bankTransferSummary.totalDue).toFixed(2)
                    : 0,
                */
                /**
                 * The applied currency id is taken directly from a client record.
                 */
                client.currencyId, 
                /**
                 * For a local payment the variable symbol value is used, otherwise null.
                 */
                invoiceLiabilities.bankAccount.localPayment ? invoiceLiabilities.bankAccount.variableSymbol : null, 
                /**
                 * The swift bank code is always defined.
                 */
                invoiceLiabilities.bankAccount.swift, 
                /**
                 * For a foreign payment the payment note value is used, otherwise null.
                 */
                !invoiceLiabilities.bankAccount.localPayment ? "" + invoiceLiabilities.bankAccount.paymentNote : null)
                : '';
        }));
        _this.feesQrData$ = combineLatest(_this.client$, _this.clientInvoiceLiabilities$, _this.clientFeeLiabilities$).pipe(map(function (_a) {
            var client = _a[0], invoiceLiabilities = _a[1], feeLiabilities = _a[2];
            return !!client && !!invoiceLiabilities && !!feeLiabilities
                ? getPaymentQR(
                /**
                 * The iban account number is always defined.
                 */
                feeLiabilities.bankAccount.iban, 
                /**
                 * If there are both unpaid invoices and unpaid fees, do not count a credit, if it exists,
                 * and use only the total fee amount owed. The reason is to avoid a misunderstanding
                 * when the same credit is applied in both places at the same moment.
                 * If there are only unpaid fees then use the total fee amount owed, less credit,
                 * if any, but only if the result is greater than 0, otherwise 0.
                 */
                +(feeLiabilities.bankTransferSummary.totalLiabilities).toFixed(2), 
                /*
                invoiceLiabilities.bankTransferSummary.totalDue > 0 && feeLiabilities.bankTransferSummary.totalDue > 0
                  ? +(feeLiabilities.bankTransferSummary.totalLiabilities).toFixed(2)
                  : feeLiabilities.bankTransferSummary.totalDue > 0
                    ? +(feeLiabilities.bankTransferSummary.totalDue).toFixed(2)
                    : 0,
                */
                /**
                 * The applied currency id is taken directly from a client record.
                 */
                client.currencyId, 
                /**
                 * For a local payment the variable symbol value is used, otherwise null.
                 */
                feeLiabilities.bankAccount.localPayment ? feeLiabilities.bankAccount.variableSymbol : null, 
                /**
                 * The swift bank code is always defined.
                 */
                feeLiabilities.bankAccount.swift, 
                /**
                 * For a foreign payment the payment note value is used, otherwise null.
                 */
                feeLiabilities.bankAccount.localPayment ? '' : "" + feeLiabilities.bankAccount.paymentNote)
                : '';
        }));
        _this.currencyMap$ = _this._store.pipe(select(currencyMap), shareReplay());
        // -- angular
        _this.contentUpdated = new EventEmitter();
        _this.contentClicked = new EventEmitter();
        /**
         * The emitted value decides if an overlay above the invoice detail card shows
         * a message explaining that the payment pairing is processed on the backend.
         */
        _this.onInvoicePaymentBackendProcessing$ = _this._store.pipe(select(paymentBackendProcessing(_this.instanceKind, PaymentIntentTypes.Invoice)), distinctUntilChanged());
        /**
         * The emitted value decides if an overlay above the fee detail card shows
         * a message explaining that the payment pairing is processed on the backend.
         */
        _this.onFeePaymentBackendProcessing$ = _this._store.pipe(select(paymentBackendProcessing(_this.instanceKind, PaymentIntentTypes.Fee)), distinctUntilChanged());
        /**
         * The following subscription is used to control the workflow of the processed payment.
         * The backend is repeatedly checked to detect when the payment is completed, allowing
         * a client to make another one. The key moment is pairing the payment with existed
         * client liabilities.
         */
        _this._onActivePaymentCard$ = _this._store.pipe(select(getActivePayment), filter(function (activePayment) { return !!activePayment && activePayment.instanceKind === _this.instanceKind; }), filter(function (activePayment) { return activePayment.status === StripePaymentStatuses.PaymentRequestSuccess; }), map(function (activePayment) { return activePayment.type; }), tap(function () {
            _this._reloadListDone = false;
            _this._reloadListCount = 0;
        }), switchMap(function (paymentIntentType) { return combineLatest(of(paymentIntentType), interval(_this._repeatTiming).pipe(take(_this._repeatReloadCount))); }), withLatestFrom(_this.clientInvoiceLiabilities$, _this.clientFeeLiabilities$), share());
        /**
         * If the repeated liability requests to the backend lead to the situation when the returned
         * data still shows unpaid invoices/fees, the backend pairing process fails to finish
         * successfully within the defined time. The error message is displayed to clients,
         * asking them to refresh the page manually.
         */
        _this._onReloadFailing$ = _this._onActivePaymentCard$.pipe(filter(function (_a) {
            var _b = _a[0], _ = _b[0], count = _b[1];
            return count === _this._repeatReloadCount - 1 ? true : false;
        }), filter(function (_a) {
            var paymentIntentType = _a[0][0], invoiceLiabilities = _a[1], feeLiabilities = _a[2];
            if (paymentIntentType === PaymentIntentTypes.Invoice) {
                /**
                 * After a successful payment there should not be any unpaid invoices.
                 * The reason is that all unpaid invoices are paid as a bulk payment.
                 */
                return invoiceLiabilities.unpaidInvoices.length > 0
                    ? true
                    : false;
                /**
                 * After a successful payment there should not be a credit value larger than zero.
                 * The reason is that if the actual credit value is larger than or equal to the number
                 * of unpaid invoices, the payment itself would not be offered to a client at all.
                 * And if the credit value is smaller, it is fully consumed for the payment.
                 */
                /*
                : invoiceLiabilities.bankTransferSummary.credit > 0
                  ? true
                  : false;
                */
            }
            /**
             * After a successful payment there should not be any unpaid fees.
             * The reason is that all unpaid fees are paid as a bulk payment.
             */
            return feeLiabilities.unpaidFees.length > 0
                ? true
                : false;
            /**
             * After a successful payment there should not be a credit value larger than zero.
             * The reason is that if the actual credit value is larger than or equal to the number
             * of unpaid fees, the payment itself would not be offered to a client at all.
             * And if the credit value is smaller, it is fully consumed for the payment.
             */
            /*
            : feeLiabilities.bankTransferSummary.credit > 0
              ? true
              : false;
            */
        }), switchMap(function () { return _this._translate.get(['bulkPayment.failPairingPayment', 'common.close']).pipe(map(function (translations) { return _this._snack.fail$(translations['bulkPayment.failPairingPayment'], translations['common.close'], 10000, 'center'); })); }), takeUntil(_this._ngOnDestroy$)).subscribe();
        /**
         * The invoice/fee unpaid liability data returned from the backend API should not contain
         * unpaid items after the payment is actually processed on the backend. If there are any,
         * a request is repeated a limited number of times.
         */
        _this._onReloadPaymentLiability$ = _this._onActivePaymentCard$.pipe(filter(function (_a) {
            var paymentIntentType = _a[0][0], invoiceLiabilities = _a[1], feeLiabilities = _a[2];
            if (paymentIntentType === PaymentIntentTypes.Invoice) {
                /**
                 * After a successful payment there should not be any unpaid invoices.
                 * The reason is that all unpaid invoices are paid as a bulk payment.
                 */
                return invoiceLiabilities.unpaidInvoices.length > 0
                    ? true
                    : false;
                /**
                 * After a successful payment there should not be a credit value larger than zero.
                 * The reason is that if the actual credit value is larger than or equal to the number
                 * of unpaid invoices, the payment itself would not be offered to a client at all.
                 * And if the credit value is smaller, it is fully consumed for the payment.
                 */
                /*
                : invoiceLiabilities.bankTransferSummary.credit > 0
                  ? true
                  : false;
                */
            }
            /**
             * After a successful payment there should not be any unpaid fees.
             * The reason is that all unpaid fees are paid as a bulk payment.
             */
            return feeLiabilities.unpaidFees.length > 0
                ? true
                : false;
            /**
             * After a successful payment there should not be a credit value larger than zero.
             * The reason is that if the actual credit value is larger than or equal to the number
             * of unpaid fees, the payment itself would not be offered to a client at all.
             * And if the credit value is smaller, it is fully consumed for the payment.
             */
            /*
            : feeLiabilities.bankTransferSummary.credit > 0
              ? true
              : false;
            */
        }), map(function (_a) {
            var paymentIntentType = _a[0][0];
            if (paymentIntentType === PaymentIntentTypes.Invoice) {
                return new LiabilityInvoiceRequest();
            }
            return new LiabilityFeeRequest();
        }));
        /**
         * Supposing the returned invoice/fee unpaid data contains no unpaid items,
         * it is necessary to get the list of existing invoices containing the last
         * ones created after pairing the last payment.
         */
        _this._onReloadCompletion$ = _this._onActivePaymentCard$.pipe(filter(function (_a) {
            var paymentIntentType = _a[0][0], invoiceLiabilities = _a[1], feeLiabilities = _a[2];
            if (paymentIntentType === PaymentIntentTypes.Invoice) {
                return invoiceLiabilities.unpaidInvoices.length > 0
                    // return invoiceLiabilities.unpaidInvoices.length > 0 || invoiceLiabilities.bankTransferSummary.credit > 0
                    ? false
                    : !_this._reloadListDone
                        ? true
                        : false;
            }
            return feeLiabilities.unpaidFees.length > 0
                // return feeLiabilities.unpaidFees.length > 0 || feeLiabilities.bankTransferSummary.credit > 0
                ? false
                : !_this._reloadListDone
                    ? true
                    : false;
        }), tap(function () {
            /**
             * Because of the BE async delay, it's necessary to postpone the final refreshing
             * of invoice and payment lists by one more interval cycle.
             */
            _this._reloadListCount += 1;
            if (_this._reloadListCount > 1) {
                _this._reloadListDone = true;
            }
        }), filter(function () { return _this._reloadListDone; }), map(function (_a) {
            var paymentIntentType = _a[0][0];
            return paymentIntentType;
        }), share());
        _this._onReloadLiabilities$ = _this._onReloadCompletion$.pipe(map(function (paymentIntentType) { return paymentIntentType === PaymentIntentTypes.Invoice
            ? new LiabilityFeeRequest()
            : new LiabilityInvoiceRequest(); }));
        _this._onReloadPaymentList$ = _this._onReloadCompletion$.pipe(map(function () { return new PaymentListRequest(); }));
        _this._onReloadInvoiceList$ = _this._onReloadCompletion$.pipe(switchMap(function () { return _this._translate.get(['bulkPayment.successPairingPayment', 'common.close']).pipe(map(function (translations) { return _this._snack.success$(translations['bulkPayment.successPairingPayment'], translations['common.close']); })); }), map(function () { return new InvoiceListRequest(); }));
        // emit that content changed so menu can resize the pop
        combineLatest(_this.latestInvoices$, _this.latestPayments$)
            .pipe(takeUntil(_this._ngOnDestroy$))
            .subscribe(function () { return _this.contentUpdated.emit(); });
        // # Store Dispatcher
        merge(_this._onReloadPaymentLiability$, _this._onReloadLiabilities$, _this._onReloadPaymentList$, _this._onReloadInvoiceList$).pipe(takeUntil(_this._ngOnDestroy$)).subscribe(_this._store);
        return _this;
    }
    BillingCardContainer.prototype._trackBy = function (index) {
        return index;
    };
    return BillingCardContainer;
}(BaseClass));
export { BillingCardContainer };
